import { useState, useEffect, useContext, useMemo } from 'react'
import Button from '@mui/material/Button'
import DialogActions from '@mui/material/DialogActions'
import { format } from 'date-fns'
import { css, Input, styled, Tooltip } from '@mui/material'
import { useTranslation } from 'react-i18next'
import {
  ModalDialog,
  TextInput,
  TextInputErrorMessage,
  TextInputLabel,
  TimeMaskInput,
  Autocomplete,
} from 'modules/core/components'
import { Controller, useForm } from 'react-hook-form'
import {
  FormControlWrapper,
  InputTextField,
  InputWrapper,
} from 'modules/team-player/components/GeneralView/GeneralView.styled'
import { DatePicker } from '@mui/x-date-pickers'
import { convertSecondsToTime } from 'modules/core/utils'
import { useReduxDispatch, useReduxSelector } from 'modules/core/hooks'
import {
  selectEventDialogData,
  selectOpenDialog,
  setEditDialogOpen,
  setViewDialogOpen,
} from 'modules/calendar/redux'
import { useScheduleCreate } from 'modules/schedule/hooks'
import { SnackbarContext } from 'modules/core/context'
import { useUserTeams } from 'modules/user/hooks'

export const InputText = styled(Input, {
  shouldForwardProp: prop => prop !== 'error',
})<{ error?: boolean }>`
  background-color: ${({ theme }) => theme.palette.darkest};
  padding: 0;
  transition: border 0.3s;
  border: 1px solid transparent;

  ${({ error, theme }) =>
    error &&
    css`
      border: 1px solid ${theme.palette.error.main};
    `}

  & .MuiInput-input {
    padding: 8px;
    font-size: 14px;
    width: 100%;
  }
`

export const StyledButton = styled(Button)`
  font-size: 14px;
  box-shadow: none;
  padding: 0 ${({ theme }) => theme.spacing(1.5)};
  border-radius: 0;
  text-transform: capitalize;
  display: flex;
  column-gap: ${({ theme }) => theme.spacing(0.5)};
`
/*
    Used to convert from time format 'HH:MM' to seconds
*/
export const convertShortTimeToSeconds = (
  time: string | null,
  returnNan = false
) => {
  if (!time) {
    return returnNan ? NaN : 0
  }
  const arr = time.split(':')
  const matches = time.match(/:/g) || []
  if (arr.length !== 2 || matches.length !== 1) {
    return returnNan ? NaN : 0
  }

  return +arr[0] * 3600 + +arr[1] * 60
}

type CalendarEventFormData = {
  title: string
  eventBeginDate: Date
  eventBeginTime: string
  eventEndDate: Date
  eventEndTime: string
  teamId: string
}

const CalendarEventDialog = () => {
  const dispatch = useReduxDispatch()
  const openDialog = useReduxSelector(selectOpenDialog)
  const eventDialogData = useReduxSelector(selectEventDialogData)
  const { title, eventBeginDate, eventBeginTime, eventEndDate, eventEndTime } =
    eventDialogData
  const { t } = useTranslation('pages', { keyPrefix: 'calendar' })
  const schedule = useScheduleCreate()
  const { showSnackbar } = useContext(SnackbarContext)
  const { data: userTeams } = useUserTeams({
    expand: ['team'],
    page_limit: 50,
  })

  const {
    register,
    handleSubmit,
    setError,
    control,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm<CalendarEventFormData>({
    defaultValues: {
      title,
      eventBeginDate: eventBeginDate ?? undefined,
      eventEndDate: eventEndDate ?? undefined,
      teamId: undefined,
    },
  })

  const [startTime, setStartTime] = useState(convertSecondsToTime(0))
  const [endTime, setEndTime] = useState(convertSecondsToTime(0))

  useEffect(() => {
    if (eventBeginTime && eventEndTime) {
      const startValue = convertSecondsToTime(
        convertShortTimeToSeconds(eventBeginTime.value)
      )
      const endValue = convertSecondsToTime(
        convertShortTimeToSeconds(eventEndTime.value)
      )

      setValue('eventBeginTime', startValue)
      setValue('eventEndTime', endValue)

      setStartTime(startValue)
      setEndTime(endValue)
    }
  }, [eventBeginTime, eventEndTime, setValue])

  useEffect(() => {
    if (eventBeginDate) {
      setValue('eventBeginDate', eventBeginDate)
      clearErrors('eventBeginDate')
    }
    if (eventEndDate) {
      setValue('eventEndDate', eventEndDate)
      clearErrors('eventEndDate')
    }
  }, [eventBeginDate, eventEndDate, setValue, clearErrors])

  const handleCloseDialog = () => {
    dispatch(setEditDialogOpen(false))
    dispatch(setViewDialogOpen(false))
  }

  useEffect(() => {
    if (title) {
      setValue('title', title)
    }
  }, [title, setValue])

  const handleOnSubmit = (data: CalendarEventFormData) => {
    const today = new Date()
    const beginDate = formatDateTime(data.eventBeginDate, data.eventBeginTime)
    const endDate = formatDateTime(data.eventEndDate, data.eventEndTime)
    const teamId = data.teamId

    if (!teamId) return setError('teamId', { type: 'manual', message: 'Error' })

    if (beginDate < today || endDate < today) {
      beginDate < today &&
        setError('eventBeginDate', {
          type: 'manual',
          message: 'Error',
        })

      endDate < today &&
        setError('eventEndDate', {
          type: 'manual',
          message: 'Error',
        })

      return
    }

    schedule.mutate(
      {
        start: formatDateTime(
          data.eventBeginDate,
          data.eventBeginTime
        ).getTime(),
        end: formatDateTime(data.eventEndDate, data.eventEndTime).getTime(),
        title: data.title,
        teamId: teamId,
      },
      {
        onError: () => {
          showSnackbar({
            message: t('modal.create.status.error'),
            type: 'error',
          })
        },
      }
    )

    showSnackbar({
      message: t('modal.create.status.request'),
      type: 'info',
    })

    handleCloseDialog()
  }

  const onSubmit = (data: CalendarEventFormData) => handleOnSubmit(data)

  const teamOptions = useMemo(() => {
    if (!userTeams) return []

    return userTeams.results.map(({ team }) => ({
      value: team?.id ?? '',
      label: team?.name ?? '',
    }))
  }, [userTeams])

  const formatDateTime = (newDate: Date, newTime: string) => {
    const resultDate = Boolean(newDate) ? newDate : new Date()
    const dateTxt = format(resultDate, 'yyyy/MM/dd')
    return new Date(dateTxt + ' ' + newTime)
  }

  const buttonDisabled =
    eventBeginTime && eventEndTime && eventBeginDate && eventEndDate
      ? formatDateTime(eventBeginDate, eventBeginTime.value) >
        formatDateTime(eventEndDate, eventEndTime.value)
      : false

  return (
    <ModalDialog
      title={t('modal.create.modalTitle')}
      open={openDialog}
      onClose={handleCloseDialog}
      keepMounted={true}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextInput
          label={t('modal.create.title')}
          inputProps={{ 'aria-label': 'title' }}
          {...register('title')}
        />
        <InputWrapper>
          <TextInputLabel>{t('modal.create.team')}</TextInputLabel>
          <FormControlWrapper>
            <Controller
              name='teamId'
              control={control}
              render={({ field: { onChange, value } }) => (
                <Autocomplete
                  onChange={onChange}
                  options={teamOptions}
                  inputProps={{ value }}
                />
              )}
            />
          </FormControlWrapper>
        </InputWrapper>
        <InputWrapper>
          <TextInputLabel>{t('modal.create.beginDate')}</TextInputLabel>
          <FormControlWrapper>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                columnGap: 4,
              }}
            >
              <Controller
                name='eventBeginDate'
                control={control}
                render={({ field }) => (
                  <DatePicker
                    {...field}
                    minDate={new Date()}
                    renderInput={params => (
                      <InputTextField
                        sx={{ flex: 1 }}
                        {...params}
                        error={Boolean(errors.eventBeginDate)}
                        InputProps={{
                          ...params.InputProps,
                          startAdornment: params.InputProps?.endAdornment,
                          endAdornment: undefined,
                        }}
                        inputProps={{
                          ...params.inputProps,
                          value: format(
                            field.value ?? new Date(),
                            `iii, do 'of' MMM yyyy`
                          ),
                          disabled: true,
                          'aria-label': 'eventBeginDate',
                        }}
                      />
                    )}
                  />
                )}
              />
              <InputText
                {...register('eventBeginTime')}
                inputProps={{ 'aria-label': 'eventBeginTime' }}
                value={startTime}
                onChange={e => setStartTime(e.target.value)}
                placeholder=''
                disableUnderline
                inputComponent={TimeMaskInput as any}
                sx={{ width: 80 }}
              />
            </div>

            <Tooltip title={''}>
              <TextInputErrorMessage></TextInputErrorMessage>
            </Tooltip>
          </FormControlWrapper>
        </InputWrapper>
        <InputWrapper>
          <TextInputLabel>{t('modal.create.endDate')}</TextInputLabel>
          <FormControlWrapper>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                columnGap: 4,
              }}
            >
              <Controller
                name='eventEndDate'
                control={control}
                render={({ field }) => (
                  <DatePicker
                    {...field}
                    minDate={new Date()}
                    renderInput={params => (
                      <InputTextField
                        sx={{ flex: 1 }}
                        {...params}
                        error={Boolean(errors.eventEndDate)}
                        InputProps={{
                          ...params.InputProps,
                          startAdornment: params.InputProps?.endAdornment,
                          endAdornment: undefined,
                        }}
                        inputProps={{
                          ...params.inputProps,
                          value: format(
                            field.value ?? new Date(),
                            `iii, do 'of' MMM yyyy`
                          ),
                          disabled: true,
                          'aria-label': 'eventEndDate',
                        }}
                      />
                    )}
                  />
                )}
              />
              <InputText
                {...register('eventEndTime')}
                inputProps={{ 'aria-label': 'eventEndTime' }}
                value={endTime}
                onChange={e => setEndTime(e.target.value)}
                placeholder=''
                disableUnderline
                inputComponent={TimeMaskInput as any}
                sx={{ width: 80 }}
              />
            </div>

            <Tooltip title={''}>
              <TextInputErrorMessage>{''}</TextInputErrorMessage>
            </Tooltip>
          </FormControlWrapper>
        </InputWrapper>

        <DialogActions>
          <StyledButton
            type={'submit'}
            color='secondary'
            variant='contained'
            disabled={buttonDisabled}
          >
            {t('modal.buttons.save')}
          </StyledButton>
        </DialogActions>
      </form>
    </ModalDialog>
  )
}

export default CalendarEventDialog
