import { Translation } from 'modules/core/components/Translation'
import { ReactNode } from 'react'
import { EventType } from 'modules/event/types'

export const TIMELINE_TEXT: Record<EventType, ReactNode> = {
  AI: Translation('components:playerControls.ai'),
  ASSET: Translation('components:playerControls.clips'),
  OFFICIAL_TICKER: Translation('components:playerControls.officialTicker'),
  USER_TICKER: Translation('components:playerControls.userTicker'),
}
