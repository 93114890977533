import { useEffect, useMemo } from 'react'
import { useMatch } from './useMatch'
import { useTickerList } from 'modules/match-info/hooks'
import { useMatchHighlightsFile } from './useMatchHighlightsFile'
import {
  Highlight,
  ListHighlightListOptions,
} from '@sporttotal-tv/dip-coaching-client'

type UseMatchHighlightsOptions = Omit<
  ListHighlightListOptions,
  'match_id' | 'expand' | 'is_manual'
>

export const useMatchHighlights = (options?: UseMatchHighlightsOptions) => {
  const { match, matchData } = useMatch()
  const { data: highlights = [] } = useMatchHighlightsFile(
    matchData?.highlights
  )

  const { data, hasNextPage, fetchNextPage } = useTickerList(
    {
      ...options,
      match_id: match?.id ?? '',
      expand: ['event'],
      is_manual: true,
    },
    {
      enabled: match?.id !== undefined,
    }
  )

  useEffect(() => {
    if (hasNextPage) fetchNextPage()
  }, [fetchNextPage, hasNextPage])

  const ticker = useMemo(
    () =>
      data?.pages.reduce<Highlight[]>((acc, page) => {
        return [...acc, ...page.results]
      }, []) ?? [],
    [data]
  )

  return { highlights, ticker }
}
